import React from 'react';

const IwocaLogoSVG = ({ className }) => {
    return (
        <svg
            width="139"
            height="47"
            viewBox="0 0 139 47"
            fill="none"
            className={className}
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.3173 11.8141C3.65889 11.8141 4.63459 10.7978 4.63459 9.40054C4.63459 8.00325 3.65889 6.98703 2.3173 6.98703C1.09767 6.86 0 8.00325 0 9.27352C0 10.6708 1.09767 11.8141 2.3173 11.8141Z"
                fill="white"
            />
            <path
                d="M5.1219 28.3273C4.39012 28.3273 3.90227 27.9462 3.90227 27.1841V16.0057C4.02423 15.6246 3.65834 15.2435 3.29245 15.2435H1.21908C0.853194 15.2435 0.487305 15.6246 0.487305 16.0057V27.5652C0.487305 30.4868 1.95086 31.8841 4.02423 31.8841C4.51208 31.8841 4.87797 31.8841 5.24386 31.757C5.48779 31.63 5.60975 31.503 5.60975 31.2489V28.9624C5.73171 28.5814 5.48779 28.3273 5.1219 28.3273Z"
                fill="white"
            />
            <path
                d="M30.1249 15.2435H27.9296C27.5637 15.2435 27.3197 15.4976 27.1978 15.7516L23.9048 26.676L20.4898 15.8787C20.3679 15.6246 20.1239 15.3706 19.758 15.3706H18.2945C17.9286 15.3706 17.6847 15.6246 17.5627 15.8787L14.1478 26.676L10.8548 15.8787C10.8548 15.4976 10.4889 15.2435 10.2449 15.2435H8.04961C7.56175 15.2435 7.19586 15.7516 7.31783 16.2597L12.1963 31.2489C12.3183 31.503 12.5622 31.757 12.9281 31.757H15.1235C15.4893 31.757 15.7333 31.503 15.8552 31.2489L19.1482 21.0868L22.4412 31.2489C22.5632 31.503 22.8071 31.757 23.173 31.757H25.3683C25.7342 31.757 25.9782 31.503 26.1001 31.2489L30.8567 16.2597C30.9786 15.7516 30.6127 15.2435 30.1249 15.2435Z"
                fill="white"
            />
            <path
                d="M40.8577 14.8616C36.2231 14.8616 32.5642 18.6724 32.5642 23.4994C32.5642 28.3264 36.2231 32.1372 40.8577 32.1372C45.4923 32.1372 49.1512 28.3264 49.1512 23.4994C49.1512 18.6724 45.4923 14.8616 40.8577 14.8616ZM40.8577 28.7075C38.0525 28.7075 36.1011 26.421 36.1011 23.4994C36.1011 20.5778 38.0525 18.2913 40.8577 18.2913C43.6628 18.2913 45.6142 20.5778 45.6142 23.4994C45.6142 26.421 43.5409 28.7075 40.8577 28.7075Z"
                fill="white"
            />
            <path
                d="M59.7615 18.2913C61.469 18.2913 62.9326 19.1805 63.7863 20.4508C64.0302 20.7048 64.3961 20.8318 64.64 20.7048L66.5914 19.8156C66.8354 19.6886 66.9573 19.4345 66.9573 19.0535C66.9573 18.9264 66.9573 18.7994 66.8354 18.6724C66.8354 18.6724 64.884 14.8616 59.7615 14.8616C55.005 14.8616 51.2241 18.5454 51.2241 23.4994C51.2241 28.4535 54.883 32.1372 59.7615 32.0102C64.64 32.0102 66.7134 28.1994 66.7134 28.1994C66.8354 28.0724 66.8354 27.9454 66.8354 27.8183C66.8354 27.5643 66.7134 27.3102 66.3475 27.0562L64.64 26.294C64.2742 26.167 63.9083 26.167 63.7863 26.5481C62.9326 27.8183 61.591 28.7075 59.8835 28.7075C57.0783 28.7075 55.005 26.294 55.005 23.3724C54.883 20.4508 56.9564 18.2913 59.7615 18.2913Z"
                fill="white"
            />
            <path
                d="M135.745 0H95.7409C94.2774 0 93.1797 1.14324 93.1797 2.66757V44.3324C93.1797 45.8568 94.2774 47 95.7409 47H135.867C137.33 47 138.306 45.8568 138.306 44.3324V2.66757C138.306 1.14324 137.208 0 135.745 0ZM133.062 41.5378H98.5461V5.46216L133.062 5.58919V41.5378Z"
                fill="white"
            />
            <path
                d="M120.011 30.105L124.28 31.8833C124.646 32.0104 125.012 31.7563 125.012 31.3752V15.3698C125.012 14.9887 124.646 14.7347 124.28 14.8617L120.011 16.7671C119.889 16.8942 119.767 17.0212 119.767 17.2752V29.5969C119.767 29.8509 119.889 29.9779 120.011 30.105Z"
                fill="white"
            />
            <path
                d="M107.205 32.0102L111.474 30.2319C111.718 30.1048 111.718 29.9778 111.718 29.7237V17.4021C111.718 17.1481 111.596 17.021 111.474 16.894L107.205 14.9886C106.839 14.8616 106.473 15.1156 106.473 15.4967V31.5021C106.473 31.8832 106.839 32.1373 107.205 32.0102Z"
                fill="white"
            />
            <path
                d="M86.4717 28.3276C85.7399 28.3276 85.374 27.9465 85.252 27.1844V16.133C85.252 15.752 84.8862 15.3709 84.5203 15.3709H82.5688C82.203 15.3709 81.8371 15.752 81.8371 16.133V16.8952C80.8614 15.752 79.1539 15.1168 77.2025 15.1168C72.5679 15.1168 69.2749 19.0547 69.2749 23.7547C69.2749 28.4547 72.5679 32.3925 77.2025 32.3925C78.91 32.3925 80.6174 32.0114 82.5688 29.852C82.9347 31.2493 84.0324 32.2655 85.374 32.2655C85.8619 32.2655 86.2277 32.2655 86.5936 32.1384C86.8376 32.0114 86.9595 31.8844 86.9595 31.6303V29.3438C87.0815 28.5817 86.8376 28.3276 86.4717 28.3276ZM77.6903 28.8357C74.8852 28.8357 72.8118 26.2952 73.0558 23.1195C73.1777 20.1979 75.7389 17.7844 78.5441 18.2925C80.1296 18.5466 81.1053 19.4357 81.8371 20.452V26.4222C80.9833 27.6925 79.6417 28.7087 77.6903 28.8357Z"
                fill="white"
            />
        </svg>
    );
};

export default IwocaLogoSVG;
