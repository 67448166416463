import React from 'react';

const CalendarIcon = ({ className }) => {
    return (
        <svg
            className={className}
            width="25"
            height="27"
            viewBox="0 0 25 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.4703 14.4163V20.9803H12.7571V14.4163H19.4703ZM18.09 0H20.7878V2.63788H22.1054C22.8583 2.63788 23.4961 2.89349 24.0189 3.40471C24.5418 3.91592 24.8032 4.53961 24.8032 5.27576V23.5569C24.8032 24.293 24.5418 24.9167 24.0189 25.4279C23.4961 25.9391 22.8583 26.1948 22.1054 26.1948H3.40877C2.65589 26.1948 2.01803 25.9391 1.49519 25.4279C0.972355 24.9167 0.710938 24.293 0.710938 23.5569V5.27576C0.710938 4.53961 0.972355 3.91592 1.49519 3.40471C2.01803 2.89349 2.65589 2.63788 3.40877 2.63788H4.72632V0H7.42415V2.63788H18.09V0ZM22.1054 23.5569V9.20191H3.40877V23.5569H22.1054Z"
                fill="#FB534A"
            />
        </svg>
    );
};

export default CalendarIcon;
