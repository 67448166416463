import React from 'react';
import TelIconSVG from 'aphrodite-shared/SVGComponents/TelIconSVG';
import EmailIconSVG from 'aphrodite-shared/SVGComponents/EmailIconSVG';
import CalendarIconSVG from 'aphrodite-shared/SVGComponents/CalendarIconSVG';
import GatsbyImg from 'gatsby-image';
import styles from './AccountantsContactSection.module.scss';

const AccountantsContactSection = ({ accountantsImages }) => {
    return (
        <div className={styles.AccountantsContactSection}>
            <div classNames={styles.LeftPane}>
                <h2>Questions?</h2>
                <h5>Chat to our accountants team</h5>
                <div className={styles.HR} />
                <ul>
                    <li>
                        <a
                            href="tel:+4420 3778 0324"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.ContactLink}>
                            <TelIconSVG className={styles.Icon} /> 020 3778 0324
                        </a>
                    </li>
                    <li>
                        <a
                            href="mailto:accountants@iwoca.co.uk"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.ContactLink}>
                            <EmailIconSVG className={styles.Icon} />
                            accountants@iwoca.co.uk
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://meetings.hubspot.com/m-white/iwoca-cbils-product"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.ContactLink}>
                            <CalendarIconSVG className={styles.Icon} />
                            Make an appointment
                        </a>
                    </li>
                </ul>
            </div>
            <div className={styles.AccountantsImages}>
                {accountantsImages.map((image) => (
                    <GatsbyImg
                        className={styles.AccountantsImage}
                        fluid={image.fluid}
                    />
                ))}
            </div>
        </div>
    );
};

export default AccountantsContactSection;
