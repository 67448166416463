import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { Location } from '@reach/router';
import GatsbyImage from 'gatsby-image';
import {
    BasicCircle,
    ArchWithCircle,
    BasicGrid,
    BasicSquare,
    WedgedArch
} from 'aphrodite-shared/SVGComponents/shapes';
import IwocaLogoSVG from 'aphrodite-shared/SVGComponents/IwocaLogoSVG';
import DynamicTrustpilot from 'aphrodite-shared/ui/DynamicTrustpilot/DynamicTrustpilot';
import AccountantsContactSection from 'aphrodite-shared/ui/AccountantsContactSection/AccountantsContactSection';
import styles from './FormPage.module.scss';
import classnames from 'classnames';
import IwLink from '../../../components/IwLink/IwLink';

const FormPage = ({ data }) => {
    const { contentfulPageForm: pageData } = data;
    const {
        hbSptFormId,
        trustedLogoList,
        iwocaLogo,
        showBenefits,
        subtitle,
        accountantsImage
    } = pageData;

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    portalId: '6334445',
                    formId: hbSptFormId,
                    target: '#hubspotForm'
                });
            }
        });

        window.addEventListener('message', (event) => {
            if (
                event.data.type === 'hsFormCallback' &&
                event.data.eventName === 'onFormSubmit'
            ) {
                const el = event.data.data.find((el) => el.name === 'email');
                if (window.dataLayer) {
                    window.dataLayer.push({
                        event: 'cbils_hs_form_submit',
                        email: el.value
                    });
                }
            }
        });

        return function cleanup() {
            window.removeEventListener('message');
        };
    }, [hbSptFormId]);

    const renderBBBLogo = () => (
        <>
            <a
                href="https://www.british-business-bank.co.uk/ourpartners/coronavirus-business-interruption-loan-scheme-cbils-2/"
                target="_blank"
                rel="noopener noreferrer">
                <img
                    src="https://images.ctfassets.net/835lsoe3tdrh/6IzwqY2vocktUhiJOjkYeS/acd09fcdcb622ead2804d4e00715c305/BBB_logo_only.png"
                    className={classnames(
                        styles.bbbLogo,
                        styles.TrustElementLink
                    )}
                    alt="bbbLogo"
                />
            </a>
            <p>
                We’re an accredited lender for CBILS, provided by the
                Government-owned British Business Bank{' '}
            </p>
        </>
    );

    const renderCreditCard = () => (
        <img
            src="https://images.ctfassets.net/835lsoe3tdrh/76lj9eX6OPdGyj84bb08F4/d0416da7788984586f7c31f4923ccc3a/creditcard.png?h=250"
            alt="creditCard"
        />
    );

    const renderBBBBenefits = () => (
        <>
            <li>
                <div className={styles.tick}></div>
                <p>No interest or repayments for a year</p>
            </li>
            <li>
                <div className={styles.tick}></div>
                <p>Repay early with no fees</p>
            </li>
            <li>
                <div className={styles.tick}></div>
                <p>Your own account manager</p>
            </li>
        </>
    );

    const renderCreditCardBenefits = () => (
        <>
            <li>
                <div className={styles.tick}></div>
                <p>Pay with the card or draw down cash</p>
            </li>
            <li>
                <div className={styles.tick}></div>
                <p>Up to 56 days interest free</p>
            </li>
            <li>
                <div className={styles.tick}></div>
                <p>No set up, FX or ATM fees</p>
            </li>
        </>
    );

    return (
        <div className={styles.FormPage}>
            <div className={styles.container}>
                <div className={styles.form}>
                    <div className={styles.logoWrapper}>
                        <IwLink to="/">
                            {iwocaLogo ? (
                                <GatsbyImage
                                    fluid={iwocaLogo.fluid}
                                    className={styles.IwocaLogo}
                                />
                            ) : (
                                <IwocaLogoSVG className={styles.IwocaLogo} />
                            )}
                        </IwLink>
                        <p className={styles.subtitle}>{subtitle}</p>
                    </div>
                    <div className={styles.trustWrapper}>
                        <div className={styles.trustMobile}>
                            <div className={styles.trustPilot}>
                                <DynamicTrustpilot />
                            </div>
                            <Location>
                                {({ location }) => {
                                    if (
                                        location.pathname ===
                                            '/register-interest/' ||
                                        location.pathname ===
                                            '/register-interest-introducers/'
                                    ) {
                                        return (
                                            <div className={styles.bbb}>
                                                <a
                                                    href="https://www.british-business-bank.co.uk/ourpartners/coronavirus-business-interruption-loan-scheme-cbils-2/"
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                    <img src="https://images.ctfassets.net/835lsoe3tdrh/6IzwqY2vocktUhiJOjkYeS/acd09fcdcb622ead2804d4e00715c305/BBB_logo_only.png" />
                                                </a>
                                                <p>
                                                    <span>CBILS</span>{' '}
                                                    Accredited Lender
                                                </p>
                                            </div>
                                        );
                                    }
                                }}
                            </Location>
                        </div>
                    </div>
                    <div id="hubspotForm" className={styles.HubspotForm}>
                        <ArchWithCircle
                            className={styles.ArchWithCircle}
                            primaryColor="denim65"
                        />
                        <BasicCircle
                            className={styles.BasicCircle}
                            size="sm"
                            color="babyBlue"
                        />
                        <BasicGrid className={styles.BasicGrid} />
                        <BasicSquare className={styles.BasicSquare} size="sm" />
                        <WedgedArch
                            className={styles.WedgedArch}
                            size="sm"
                            color="snow"
                        />
                    </div>
                    <div className={styles.ExtraTrustMobile}>
                        {trustedLogoList &&
                            trustedLogoList.length > 0 &&
                            trustedLogoList.map((trustElement) => {
                                return (
                                    <a
                                        className={styles.TrustElementLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={trustElement.url}>
                                        <GatsbyImage
                                            fluid={trustElement.logo.fluid}
                                            className={styles.TrustElement}
                                            key={trustElement.id}
                                        />
                                    </a>
                                );
                            })}
                    </div>
                    {accountantsImage && (
                        <AccountantsContactSection
                            accountantsImages={accountantsImage}
                        />
                    )}
                </div>
                <div className={styles.trust}>
                    {trustedLogoList &&
                        trustedLogoList.length > 0 &&
                        trustedLogoList.map((trustElement) => {
                            return (
                                <a
                                    className={styles.TrustElementLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={trustElement.url}>
                                    <GatsbyImage
                                        fluid={trustElement.logo.fluid}
                                        className={styles.TrustElement}
                                        key={trustElement.id}
                                    />
                                </a>
                            );
                        })}
                    <DynamicTrustpilot
                        className={classnames(
                            styles.TrustpilotLeft,
                            styles.TrustElementLink
                        )}
                    />
                    {showBenefits && (
                        <ul className={styles.BenefitsLists}>
                            <Location>
                                {({ location }) => {
                                    if (
                                        location.pathname ===
                                            '/register-interest/' ||
                                        location.pathname ===
                                            '/register-interest-introducers/'
                                    ) {
                                        return renderBBBBenefits();
                                    }
                                    if (
                                        location.pathname ===
                                        '/credit-card-early-access/'
                                    ) {
                                        return renderCreditCardBenefits();
                                    }
                                }}
                            </Location>
                        </ul>
                    )}
                    <Location>
                        {({ location }) => {
                            if (
                                location.pathname === '/register-interest/' ||
                                location.pathname ===
                                    '/register-interest-introducers/'
                            ) {
                                return renderBBBLogo();
                            }
                            if (
                                location.pathname ===
                                '/credit-card-early-access/'
                            ) {
                                return renderCreditCard();
                            }
                        }}
                    </Location>
                </div>
            </div>
        </div>
    );
};

export default FormPage;

export const query = graphql`
    query FormPageQuery($id: String!) {
        contentfulPageForm(id: { eq: $id }) {
            hbSptFormId
            iwocaLogo {
                fluid(maxWidth: 600, quality: 80) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            subtitle
            trustedLogoList {
                name
                logo {
                    fluid(maxWidth: 600, quality: 80) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                }
                url
            }
            accountantsImage {
                fluid(maxWidth: 600, quality: 80) {
                    ...GatsbyContentfulFluid
                }
            }
            showBenefits
        }
    }
`;
